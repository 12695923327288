/* eslint-disable */

import axios from "axios";
import socketIOClient from "socket.io-client";

const JWT = localStorage.getItem("JWT")

const onTest = localStorage.getItem("testing")
const dev = false
const local = "http://localhost:5000"
const staging = process.env.REACT_APP_API_URL
const baseURL = dev? local : onTest==="true"&&onTest!==null ? staging : process.env.REACT_APP_API_URL

console.log("baseUrl", baseURL)
const api_url = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + JWT
    }
})

export const socket = socketIOClient(baseURL);

export const Login = (payload) =>{
        Object.assign(payload, {from_kiosk: true })
        console.log(payload)
        return api_url.post('/auth/login', payload)
}

export const VerifyToken = async(token) =>{
    return api_url.post('/auth/verify-token', { token: token })
}

export const GetOrdersToday = (store) => {
    return api_url.post(`/orders/get/today`, {
        store: store,
        date: null,
        dateRange: null
    })
}

export const UpdateOrder = (_id, elapsed, status) =>{
    console.log(_id, status)
    return api_url.post('/orders/update', {
        query: { _id: _id},
        data: { kitchenStatus: status, elapsedTime: elapsed }
    })
}
export const GetLatestID = (store) => {
    return api_url.get(`/orders/get-latest-id/${store}`)
}